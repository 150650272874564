var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"table-container-custom"},[(_vm.isLoading)?_c('PreLoader'):_vm._e(),_c('div',{staticClass:"row"},[_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.educationalScheduleTime.educationalGroupInfoData
          .educationalGroupNameCurrent,"title":_vm.$t('EducationalGroups.name'),"imgName":'EducationalGroups.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-6',"value":_vm.educationalScheduleTime.placeInfoData.placeNameCurrent,"title":_vm.$t('Places.name'),"imgName":'places.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-4',"value":_vm.educationalScheduleTime.appointmentTypeNameCurrent,"title":_vm.$t('ConstantsListSelect.AppointmentTypesName'),"imgName":'AppointmentTypes.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-4',"value":_vm.formateDateTimeLang(
          _vm.educationalScheduleTime.dateTimeFromDate,
          _vm.educationalScheduleTime.dateTimeFromTime
        ),"title":_vm.$t('from'),"imgName":'dateAndTime.svg'}}),_c('DataLabelGroup',{attrs:{"className":'col-md-4',"value":_vm.formateDateTimeLang(
          _vm.educationalScheduleTime.dateTimeToDate,
          _vm.educationalScheduleTime.dateTimeToTime
        ),"title":_vm.$t('to'),"imgName":'dateAndTime.svg'}}),_c('form',{staticClass:"col-lg-10 col-md-8",attrs:{"autocomplete":"off","method":"post"},on:{"submit":function($event){$event.preventDefault();return _vm.search()}}},[_c('CustomInput',{attrs:{"id":"inputScan","value":_vm.inputScan,"title":_vm.$t('Users.code'),"imgName":'qr-code.svg'},on:{"changeValue":function($event){_vm.inputScan = $event}}})],1),_c('button',{staticClass:"col-lg-2 col-md-4 btn btn-submit",attrs:{"disabled":!_vm.hasDataFiltered,"name":"submit","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.addEducationalGroupAttendance.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t("EducationalGroupAttendances.attend"))+" ")]),_c('EducationalScheduleTimeDetailsQRCodeAttendUsers',{attrs:{"usersList":_vm.studentsList,"UsersDataMsg":_vm.$t('students.data')}}),_c('EducationalScheduleTimeDetailsQRCodeAttendUsers',{attrs:{"usersList":_vm.employeesList,"UsersDataMsg":_vm.$t('employees.data')}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }